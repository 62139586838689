import React from "react";
import "./loader.css";
export const Loader = () => {
  return (
    <div class="cell">
      <div class="wrapper">
        <div class="spinner spinner3"></div>
      </div>
    </div>
  );
};
