const PREFIX = "RUPISEVA: ";
export const mixpanelEvents = {
  HOMEPAGE_VIEWED: `${PREFIX} Homepage viewed`,
  BOOK_NOW_BUTTON_CLICKED: `${PREFIX} Book now clicked`,
  DETAILS_PAGE_VIEWED: `${PREFIX} Form viewed`,
  FORM_VALIDATION_FAILED: `${PREFIX} Form validation failed`,
  FORM_SUBMITTED_SUCCESSFULLY: `${PREFIX} Form submitted`,
  FORM_SUBMISSION_ERROR: `${PREFIX} Form submission error`,
  ASSISTED_PAYMENT_STATUS_VIEWED: `${PREFIX} Assisted payment status viewed`,
  ASSISTED_PAYMENT_SUCCESS: `${PREFIX} Assisted payment success`,
  ASSISTED_PAYMENT_FAILED: `${PREFIX} Assisted payment failed`,
  ASSISTED_PAYMENT_PENDING: `${PREFIX} Assisted payment pending`,
  PAYMENT_STATUS_VIEWED: `${PREFIX} Payment status viewed`,
  PAYMENT_SUCCESS_VIEWED: `${PREFIX} Payment success viewed`,
  PAYMENT_FAILED_VIEWED: `${PREFIX} Payment failed viewed`,
  PAYMENT_PENDING_VIEWED: `${PREFIX} Payment pending viewed`,
  TESTIMONIAL_PLAYED: `${PREFIX} Testimonial played`,
  TESTIMONIAL_PAUSED: `${PREFIX} Testimonial paused`,
  TESTIMONIAL_END: `${PREFIX} Testimonial end`,
  TESTIMONIAL_PLAY_ERROR: `${PREFIX} Testimonial error`,
  CHAT_NOW_CLICKED: `${PREFIX} Chat now clicked`,
  VIDEO_RENDERED: `${PREFIX} Video rendered`,
  VIDEO_PLAYED: `${PREFIX} Video played`,
  VIDEO_PAUSED: `${PREFIX} Video paused`,
  VIDEO_END: `${PREFIX} Video end`,
  VIDEO_ERROR: `${PREFIX} Video error`,
};
