import React, { useContext, useEffect } from "react";
import { Routes, Route, useSearchParams } from "react-router-dom";
import { PAGES } from "./utils/constant";
import { Home } from "./pages/Home";
import { Details } from "./pages/Details";
import { PaymentSuccess } from "./pages/PaymentStatus/Success";
import { PaymentFailed } from "./pages/PaymentStatus/Failed";
import { AssistedPaymentSuccess } from "./pages/PaymentStatus/AssistedSuccess";
import { AssistedPaymentFailed } from "./pages/PaymentStatus/AssistedFailed";
import { PaymentStatus } from "./pages/PaymentStatus/Status";
import { AssistedPaymentStatus } from "./pages/PaymentStatus/AssistedStatus";
import { context } from "./store/context";
import { initLocalization } from "./utils/localization";
import { useLanguage } from "./hooks/useLanguage";
import { usePrice } from "./hooks/usePrice";
import mixpanel from "mixpanel-browser";
import { Version } from "./utils/constant";
import {
  STORAGE_KEY,
  getStorageItem,
  setStorageItem,
} from "./services/storage";
import { campaignParams } from "./services/mixpanel";
import { Terms } from "./pages/Terms";
import { DeliveryPolicy } from "./pages/DeliveryPolicy";
import { RefundPolicy } from "./pages/RefundPolicy";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { useHomeVariant } from "./hooks/useHomeVariant";
let BOOTED = false;
export const App = () => {
  const {
    setUtmSource,
    setUtmMedium,
    setUtmCampaign,
    setUtmContent,
    setLang,
    setChat,
  } = useContext(context);
  const { lang } = useLanguage();
  const { price } = usePrice();
  const { variantName } = useHomeVariant();
  const [searchParams] = useSearchParams();
  const utmSource = searchParams.get("utm_source");
  const utmMedium = searchParams.get("utm_medium");
  const utmCampaign = searchParams.get("utm_campaign");
  const utmContent = searchParams.get("utm_content");
  const enableChat = !(searchParams.get("chat") === "false");
  const source = utmSource || getStorageItem(STORAGE_KEY.UTM_SOURCE);
  if (!BOOTED) {
    initLocalization(lang);
    setLang(lang);
    mixpanel.register({
      version: Version,
      plan: price,
      language: lang,
      chatEnabled: enableChat,
      source: source,
      homepage: variantName,
    });
    campaignParams();
    BOOTED = true;
  }

  useEffect(() => {
    if (utmSource) {
      setUtmSource(utmSource);
      setStorageItem(STORAGE_KEY.UTM_SOURCE, utmSource);
    }
    if (utmMedium) {
      setUtmMedium(utmMedium);
    }
    if (utmCampaign) {
      setUtmCampaign(utmCampaign);
    }
    if (utmContent) {
      setUtmContent(utmContent);
    }
    setChat(enableChat);
  }, []);

  return (
    <Routes>
      <Route exact path={PAGES.home} element={<Home />} />
      <Route path={PAGES.details} element={<Details />} />
      <Route path={PAGES.paymentsSuccess} element={<PaymentSuccess />} />
      <Route path={PAGES.paymentFailed} element={<PaymentFailed />} />
      <Route
        path={PAGES.assistedSuccess}
        element={<AssistedPaymentSuccess />}
      />
      <Route path={PAGES.assistedFailed} element={<AssistedPaymentFailed />} />
      <Route path={PAGES.paymentStatus} element={<PaymentStatus />} />
      <Route
        path={PAGES.paymentStatusAssisted}
        element={<AssistedPaymentStatus />}
      />
      <Route path={PAGES.terms} element={<Terms />} />
      <Route path={PAGES.privacyPolicy} element={<PrivacyPolicy />} />
      <Route path={PAGES.deliveryPolicy} element={<DeliveryPolicy />} />
      <Route path={PAGES.refundPolicy} element={<RefundPolicy />} />
    </Routes>
  );
};
