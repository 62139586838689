import { createUseStyles } from "react-jss";
import PricingBanner from "../../../assets/img/price-banner.svg";
export const useStyles = createUseStyles({
  root: {
    padding: "0 1.75em",
    fontFamily: "Poppins",
  },
  banner: {
    position: "absolute",
    top: 22,
    right: 0,
    backgroundImage: `url(${PricingBanner})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top right",
    backgroundSize: "contain",
    width: 189,
    height: 181,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
    paddingLeft: "15%",
    paddingTop: "5%",
  },
  bannerHeader: {
    fontSize: "1.250rem",
    lineHeight: 1.5,
    fontWeight: 500,
    fontStyle: "italic",
    color: "#FFFFFF",
    margin: 0,
  },
  bannerPrice: {
    fontSize: "2.5rem",
    lineHeight: 1.2,
    fontWeight: 700,
    fontStyle: "italic",
    color: "#FFFFFF",
    margin: 0,
  },
  bannerSubHeader: {
    fontSize: "13px",
    lineHeight: 1.2,
    fontWeight: 300,
    color: "#FFFFFF",
    margin: 0,
    textAlign: "center",
  },

  header: {
    fontSize: "2rem",
    lineHeight: 1.5,
    width: "calc(100% - 115px)",
  },
  underLined: {
    display: "block",
  },
  subHeader: {
    fontSize: "1.250rem",
    lineHeight: 1.5,
    fontWeight: 500,
  },
  colorText: {
    fontWeight: 700,
    background: "-webkit-linear-gradient( 0deg, #2487E1 0%, #01397B 114.73% )",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
  text: {
    fontSize: "1.250rem",
    lineHeight: 1.5,
    fontWeight: 400,
    margin: ".750rem 1.125rem",
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  rowIcon: {},
});
