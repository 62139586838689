import React, { useState, useRef } from "react";
import ChecvronRightIcon from "../../assets/icon/chevron-right.svg";
import { useStyles } from "./accordion.styles";
import classNames from "classnames";

export const Accordion = ({ items = [], className }) => {
  const [active, setActive] = useState(null);

  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };
  return (
    <div className={className}>
      {items.map(({ id, title, description, list }) => (
        <AccordionItem
          key={id}
          handleToggle={handleToggle}
          active={active}
          id={id}
          title={title}
          description={description}
          list={list}
        />
      ))}
    </div>
  );
};

const AccordionItem = (props) => {
  const contentEl = useRef();
  const { handleToggle, active, id, title, description, list } = props;
  const classes = useStyles();
  return (
    <div className={classes.item}>
      <div
        className={classNames(classes.toggle, {
          [classes.toggleActive]: active === id,
        })}
        onClick={() => handleToggle(id)}
      >
        <h4 className={classes.title}>{title}</h4>
        <img
          src={ChecvronRightIcon}
          className={classNames(classes.icon, {
            [classes.iconActive]: active === id,
          })}
        />
      </div>
      <div
        ref={contentEl}
        className={classNames(classes.collapse, {
          [classes.collapseShow]: active === id,
        })}
        style={
          active === id
            ? { height: contentEl.current.scrollHeight }
            : { height: "0px" }
        }
      >
        <div className={classes.description}>
          <p>{description}</p>
          {list && (
            <ul className={classes.list}>
              {list.map((_, index) => (
                <li key={index}>{_}</li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};
