import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  root: {
    margin: "5vh 8% 5%",
  },
  header: {
    fontSize: 20,
    fontWeight: 700,
    margin: 0,
  },
  subheader: {
    fontSize: 16,
    fontWeight: 700,
    margin: "4px 0",
  },
  text: {
    fontSize: 14,
    margin: 0,
    color: "#515978",
  },
  section: {
    marginTop: 8,
    marginBottom: 8,
  },
  listHeader: {
    fontSize: 14,
    margin: 0,
    color: "#515978",
    fontWeight: 500,
  },
  contentHeader: {
    fontSize: 16,
    margin: "4px 0",
    color: "#171a27",
    fontWeight: 500,
  },
  content: {
    fontSize: 14,
    margin: 0,
    color: "#515978",
    fontWeight: 500,
  },
  list: {
    fontSize: 18,
    marginTop: 2,
  },
});
