import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import mixpanel from "mixpanel-browser";
import { ScrollReset } from "./components/ScrollReset";
import { BrowserRouter } from "react-router-dom";
import { Version, PlanAmount } from "./utils/constant";
import { ContextProvider } from "./store/context";
const root = ReactDOM.createRoot(document.getElementById("root"));

mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY);

root.render(
  <React.StrictMode>
    <ContextProvider>
      <BrowserRouter>
        <ScrollReset>
          <App />
        </ScrollReset>
      </BrowserRouter>
    </ContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
