import React, { useState } from "react";
import { useStyles } from "./video.styles";
import YouTube from "react-youtube";
import mixpanel from "mixpanel-browser";
import { mixpanelEvents } from "../../../services/mixpanel/events";
export const Video = () => {
  const [isPlayerReady, setIsPlayerReady] = useState(false);
  const classes = useStyles({ isPlayerReady });
  const onPlayerReady = () => {
    setIsPlayerReady(true);
    mixpanel.track(mixpanelEvents.VIDEO_RENDERED);
  };
  const onVideoPlay = () => {
    mixpanel.track(mixpanelEvents.VIDEO_PLAYED);
  };

  const onVideoPause = () => {
    mixpanel.track(mixpanelEvents.VIDEO_PAUSED);
  };
  const onVideoEnd = () => {
    mixpanel.track(mixpanelEvents.VIDEO_END);
  };
  const onError = () => {
    mixpanel.track(mixpanelEvents.VIDEO_ERROR);
  };
  return (
    <div>
      <div className={classes.thumbnailContainer}>
        <img
          src={"https://img.youtube.com/vi/2LkpsldCRA4/maxresdefault.jpg"}
          className={classes.thumbnail}
        />
      </div>
      <YouTube
        videoId="2LkpsldCRA4"
        onReady={onPlayerReady}
        onPlay={onVideoPlay}
        onPause={onVideoPause}
        onEnd={onVideoEnd}
        onError={onError}
        className={classes.container}
        iframeClassName={classes.iframe}
        opts={{
          playerVars: {
            rel: 0,
            autoplay: 0,
            showInfo: 0,
            mute: 0,
          },
        }}
      />
    </div>
  );
};
