export const getPrice = (variantPrice) => {
  switch (variantPrice) {
    case "1":
      return 99;
    case "2":
      return 149;
    case "3":
      return 199;
    case "4":
      return 249;
    case "5":
      return 299;
    default:
      return 999;
  }
};

export const getCookies = function () {
  var pairs = document.cookie.split(";");
  var cookies = {};
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    cookies[(pair[0] + "").trim()] = decodeURI(pair.slice(1).join("="));
  }
  return cookies;
};
