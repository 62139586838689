import React from "react";
import CibilImg from "../../../assets/img/cibil.png";
import { useStyles } from "./cibilscore.styles";
import VerticalSeparator from "../../../assets/img/line-vertical.svg";
import LeftSeparator from "../../../assets/img/line-left.svg";
import RightSeparator from "../../../assets/img/line-right.svg";
import CrossRed from "../../../assets/icon/cross-red.svg";
import TickGreen from "../../../assets/icon/tick-green.svg";
export const CibilScore = () => {
  const classes = useStyles();

  return (
    <section>
      <div className={classes.imgWrpr}>
        <img src={CibilImg} className={classes.img} />
      </div>

      {/* <div className={classes.content}>
        <div className={classes.section}>
          <h3 className={classes.header}>Low</h3>
          <p className={classes.subHeader}>Cibil Score</p>
          <img src={LeftSeparator} width={87} className={classes.separator} />
          <div className={classes.rowWrpr}>
            <div className={classes.row}>
              <img src={CrossRed} />
              <p className={classes.text}>Loan</p>
            </div>
            <div className={classes.row}>
              <img src={CrossRed} />
              <p className={classes.text}>Credit Card</p>
            </div>
          </div>
        </div>
        <div className={classes.verticalSeparatorWrpr}>
          <img src={VerticalSeparator} />
        </div>
        <div className={classes.section}>
          <h3 className={classes.header}>High</h3>
          <p className={classes.subHeader}>Cibil Score</p>
          <img src={RightSeparator} width={87} className={classes.separator} />
          <div className={classes.rowWrpr}>
            <div className={classes.row}>
              <img src={TickGreen} />
              <p className={classes.text}>Loan</p>
            </div>
            <div className={classes.row}>
              <img src={TickGreen} />
              <p className={classes.text}>Credit Card</p>
            </div>
          </div>
        </div>
      </div> */}
    </section>
  );
};
