const PREFIX = "Rupiseva: ";
export const clevertapEvents = {
  USER_DETAILS_FORM_SUBMIT_SUCCESS: `${PREFIX}Form submit success`,
  USER_DETAILS_FORM_SUBMIT_FAILED: `${PREFIX}Form submit failed`,
  PAYMENT_SUCCESS_PAGE_VIEWED: `${PREFIX}Payment success viewed`,
  PAYMENT_FAILED_PAGE_VIEWED: `${PREFIX}Payment failed viewed`,
  PAYMENT_PENDING_PAGE_VIEWED: `${PREFIX}Payment pending viewed`,
  ASSISTED_PAYMENT_SUCCESS_PAGE_VIEWED: `${PREFIX}Assisted Payment success viewed`,
  ASSISTED_PAYMENT_FAILED_PAGE_VIEWED: `${PREFIX}Assisted Payment failed viewed`,
  ASSISTED_PAYMENT_PENDING_PAGE_VIEWED: `${PREFIX}Assisted Payment pending viewed`,
};
