import { useSearchParams } from "react-router-dom";
import {
  DEFAULT_HOMEPAGE_VARIANT,
  HOMEPAGE_VARIANT,
  queryParams,
} from "../utils/constant";
import {
  STORAGE_KEY,
  getStorageItem,
  setStorageItem,
} from "../services/storage";
export function useHomeVariant() {
  const [searchParams] = useSearchParams();
  const currentVariant = searchParams.get(queryParams.HOMEPAGE_VARIANT);
  const savedVariant = getStorageItem(STORAGE_KEY.HOMEPAGE_VARIANT);
  const variant = currentVariant || savedVariant || DEFAULT_HOMEPAGE_VARIANT;

  if (variant && variant !== savedVariant) {
    setStorageItem(STORAGE_KEY.HOMEPAGE_VARIANT, variant);
  }

  return {
    variant: variant,
    variantName:
      variant === HOMEPAGE_VARIANT.TESTIMONIALS_TOP
        ? "TESTIMONIALS_TOP"
        : "DEFAULT",
  };
}
