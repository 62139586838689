import React from "react";
import { useStyles } from "./offering.styles";
import TickRoundIcon from "../../../assets/icon/tick-round.svg";
import TipsHuman from "../../../assets/img/tips-human.svg";
import { useNavigate } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { mixpanelEvents } from "../../../services/mixpanel/events";
import { PAGES } from "../../../utils/constant";
import { useTranslation } from "react-i18next";
export const Offerings = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const onBookNow = () => {
    mixpanel.track(mixpanelEvents.BOOK_NOW_BUTTON_CLICKED);
    navigate(PAGES.details);
  };

  return (
    <section className={classes.root}>
      <div className={classes.content}>
        <div className={classes.headerWrpr}>
          <h1 className={classes.header}>{t("home.tips.header")}</h1>
          <img src={TipsHuman} className={classes.tipHuman} />
        </div>
        <div className={classes.list}>
          <div className={classes.listItem}>
            <img src={TickRoundIcon} />
            <p className={classes.listText}>{t("home.tips.tip1")}</p>
          </div>
          <div className={classes.listItem}>
            <img src={TickRoundIcon} />
            <p className={classes.listText}>{t("home.tips.tip2")}</p>
          </div>
          <div className={classes.listItem}>
            <img src={TickRoundIcon} />
            <p className={classes.listText}>{t("home.tips.tip3")}</p>
          </div>
          <div className={classes.listItem}>
            <img src={TickRoundIcon} />
            <p className={classes.listText}>{t("home.tips.tip4")}</p>
          </div>
        </div>
      </div>
      {/* <div className={classes.ctaContainer}>
        <button className={classes.btn} onClick={onBookNow}>
          Book Call Now!
        </button>
      </div> */}
    </section>
  );
};
