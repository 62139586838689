import { createUseStyles } from "react-jss";
import UnderstnadingBg from "../../../assets/img/understanding-bg.png";
import MonthBg from "../../../assets/img/month-bg.png";
export const useStyles = createUseStyles({
  root: {
    backgroundImage: `url(${UnderstnadingBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    padding: "3em 0",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
  },
  understandingSection: {
    padding: "0 1.75em",
  },
  monthsSection: {
    padding: "4em 1.75em",
    backgroundImage: `url(${MonthBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  priceSection: {
    padding: "0.5em 1.75em 2em",
  },
  text: {
    fontSize: "1.25rem",
    fontWeight: 600,
    lineHeight: 1.5,
  },
  textItalic: {
    fontSize: "1.25rem",
    fontWeight: 700,
    lineHeight: 1.5,
    fontStyle: "italic",
  },
});
