import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  root: {
    padding: "0 2em",
    textAlign: "center",
    height: "80vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    marginTop: "1em",
    marginBottom: ".5em",
  },
  text: { margin: 0 },
  ctaContainer: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    padding: "32px",
    boxSizing: "border-box",
    "@media (min-width: 480px)": {
      width: "480px",
    },
  },
  btn: {
    width: "100%",
    backgroundColor: "#1C75BC",
    padding: "10px 16px",
    fontSize: "1.125rem",
    fontWeight: 600,
    color: "#FFFFFF",
    border: 0,
    borderRadius: 6,
    cursor: "pointer",
  },
});
