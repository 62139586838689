import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  root: {
    padding: 32,
  },
  title: {
    color: "#374695",
    fontSize: "2rem",
    textAlign: "center",
    lineHeight: 1.5,
    margin: 0,
    marginBottom: "2rem",
  },
  box: {
    height: 356,
    width: 200,
    border: "2px solid #EBF6FF",
    borderRadius: 8,
    overflow: "hidden",
  },
  container: {
    margin: "0 16px",
  },
});
