import React from "react";
import { TopNav } from "../TopNav";
import { Footer } from "../Footer";
import { useStyles } from "./layout.styles";
export const Layout = ({ children, showFooterCta = false }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <TopNav />
      <main className={classes.main}>{children}</main>
      <Footer showCta={showFooterCta} />
    </div>
  );
};
