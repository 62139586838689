import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  root: {
    padding: "1em 1em .625em 1.5em",
    boxShadow: "0px 4px 45px rgba(0, 0, 0, 0.15)",
    position: "sticky",
    backgroundColor: "#FFFFFF",
    zIndex: 1000,
    top: 0,
    display: "flex",
    justifyContent: "space-between",
  },
  chatBtn: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#25D366",
    color: "#FFFFFF",
    fontSize: "15px",
    fontWeight: 600,
    lineHeight: 1.5,
    borderRadius: 16,
    padding: "8px 16px",
    border: 0,
    textDecoration: "none",
    fontFamily: "Poppins",
  },
  btnText: {
    margin: 0,
    marginLeft: 9,
  },
  logo: {
    width: 112,
    height: 38,
  },
});
