import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  root: {
    backgroundColor: "#EBF6FF",
    padding: "3em 1.75em 4em",
  },
  header: {
    fontSize: "2rem",
    fontWeight: 600,
    lineHeight: 1.5,
    textAlign: "center",
  },
});
