import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  root: {
    position: "fixed",
    bottom: "2rem",
    width: "100%",
    zIndex: 101,
    "@media (min-width: 480px)": {
      maxWidth: "480px",
    },
  },
  btn: {
    width: "90%",
    marginLeft: "5%",
    backgroundColor: "#FF7800",
    padding: "12px 32px",
    fontSize: "1.5rem",
    fontWeight: 600,
    color: "#FFFFFF",
    border: "3px solid #FDEFEF",
    borderRadius: 10,
    filter: "drop-shadow(0px 1px 32px rgba(0, 0, 0, 0.25))",
  },
});
