import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  root: {},
  header: {
    textAlign: "center",
  },
  form: {
    margin: "0 10%",
  },
  questionWrpr: {
    marginBottom: "1.5em",
  },
  question: {
    fontSize: "1.125rem",
    fontWeight: 500,
  },

  option: {
    display: "flex",
    marginTop: 4,
    marginBottom: 4,
    alignItems: "center",
  },
  input: {
    height: 20,
    width: 20,
    flexShrink: 0,
  },
  label: {
    fontSize: 16,
    marginLeft: 8,
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "1em 0",
    "& label": {
      marginBottom: "0.5em",
      fontSize: "1.125rem",
      fontWeight: 500,
    },
    "& input": {
      width: "90%",
      padding: "10px 16px",
      fontSize: "1.125rem",
      border: "1px solid #1C75BC",
      borderRadius: 4,
    },
  },
  helperText: {
    color: "#FC2A45",
    fontSize: "14px",
    marginTop: "4px",
    fontFamily: "Poppins",
    fontWeight: 400,
  },
  slots: {
    display: "flex",
    flexWrap: "wrap",
  },
  box: {
    border: "1px solid #6C8BBB",
    padding: "10px 24px",
    margin: ".5em 10px .5em 0",
    borderRadius: 4,
  },
  activeBox: {
    backgroundColor: "#E4F8FF",
  },
  btn: {
    width: "100%",
    padding: "12px 16px",
    fontSize: "1.5rem",
    fontWeight: 600,
    color: "#FFFFFF",
    backgroundColor: "#FF7800",
    border: "3px solid #FDEFEF",
    borderRadius: 8,
    filter: "drop-shadow(0px 1px 32px rgba(0, 0, 0, 0.25))",
    margin: "2em 0",
    "&:disabled": {
      backgroundColor: "#7cb7e6",
    },
  },
});
