export const optInGp = async (phone) => {
    return new Promise ((resolve) => {
  try {
    fetch(`https://gupshupoptin-zn6mbtczxq-uc.a.run.app?phone=${phone}`, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {resolve(response)})
      .catch((error) => {resolve(error)});
  } catch (error) {}
});
}
