export const detailsValidationRules = [
  {
    field: "name",
    method: (val) => !val,
    validWhen: false,
    message: "Name is required.",
  },
  {
    field: "name",
    method: (val) => val.length > 1,
    validWhen: true,
    message: "Name is too short.",
  },
  {
    field: "dob",
    method: (val) => !val,
    validWhen: false,
    message: "DOB is required.",
  },
  {
    field: "phoneNumber",
    method: (val) => !val,
    validWhen: false,
    message: "Mobile number is required.",
  },
  {
    field: "phoneNumber",
    method: (val) =>
      /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/.test(val),
    validWhen: true,
    message: "Mobile number is not valid.",
  },
  {
    field: "email",
    method: (val) =>
      !val || /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val),
    validWhen: true,
    message: "Email is not valid.",
  },
  {
    field: "pincode",
    method: (val) => !val,
    validWhen: false,
    message: "PIN code is required.",
  },
  {
    field: "pincode",
    method: (val) => /^\d+$/.test(val) && val.length === 6,
    validWhen: true,
    message: "PIN code is not valid.",
  },
  {
    field: "monthlyIncome",
    method: (val) => !val,
    validWhen: false,
    message: "Monthly Income is required.",
  },
  {
    field: "rejectedCredit",
    method: (val) => !val,
    validWhen: false,
    message: "Choose one option",
  },
  {
    field: "cibilScore",
    method: (val) => !val,
    validWhen: false,
    message: "Fill your cibil score",
  },
];
