import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  root: {
    padding: "0 32px 4rem",
  },
  header: {
    fontSize: "2rem",
    lineHeight: 1.5,
    color: "#374695",
    textAlign: "center",
  },
  imgContainer: {
    width: "100%",
    textAlign: "center",
    paddingTop: "1em",
    paddingBottom: "3em",
  },
  ctaContainer: {
    width: "100%",
    padding: "32px 48px",
    boxSizing: "border-box",
    "@media (min-width: 480px)": {
      width: "480px",
    },
  },
  btn: {
    width: "100%",
    backgroundColor: "rgb(238, 121, 48)",
    padding: "12px 16px",
    fontSize: "1.25rem",
    fontWeight: 600,
    color: "#FFFFFF",
    border: 0,
    borderRadius: 6,
  },
  step1: {
    background: "linear-gradient(281.02deg, #96E3FF 1.06%, #E4F8FF 92.15%)",
    borderRadius: 22,
    padding: "22px 24px 22px 42px",
    color: "#00A1DA",
    marginBottom: 16,
    position: "relative",
  },
  step2: {
    background: "linear-gradient(279.89deg, #FFD4B1 1.12%, #FFEFE1 100%)",
    borderRadius: 22,
    padding: "22px 24px 22px 42px",
    color: "#C65B03",
    marginBottom: 16,
    position: "relative",
  },
  step3: {
    background: "linear-gradient(280.12deg, #8DFFC8 0.82%, #E1FFF1 100%)",
    borderRadius: 22,
    padding: "22px 24px 22px 42px",
    color: "#00AA58",
    position: "relative",
  },
  stepIcon: {
    width: "54px",
  },
  stepTitleWrpr: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontFamily: "Poppins",
    fontSize: "2rem",
    lineHeight: 1.5,
    fontWeight: "400",
    margin: 0,
  },
  number: {
    fontFamily: "Poppins",
    fontSize: "2rem",
    lineHeight: 1.5,
    fontWeight: "600",
    margin: 0,
  },
  subTiitle: {
    fontFamily: "Poppins",
    fontSize: "1.5rem",
    lineHeight: 1.5,
    fontWeight: "600",
    margin: 0,
    color: "#000000",
  },
  timeLine: {
    borderLeft: "2px solid #83C3FF",
    position: "absolute",
    top: "40px",
    left: "24px",
    height: "105%",
    zIndex: 100,
  },
  circle: {
    height: "12px",
    width: "12px",
    borderRadius: "50%",
    backgroundColor: "#83C3FF",
    position: "absolute",
    left: "19px",
    top: "30px",
  },
});
