import React, { useState, useEffect, useRef } from "react";
import { useStyles } from "./assisted.styles";
import { getLead, getTransaction, updateTransaction } from "../../../services";
import { PAGES, PG_PAYMENT_STATUS } from "../../../utils/constant";
import { Layout } from "../../../components/Layout";
import { Loader } from "../../../components/Loader";
import SuccessImg from "../../../assets/img/success.svg";
import FailedImg from "../../../assets/img//failed.svg";
import { useNavigate } from "react-router-dom";
import { checkPaymentStatus } from "../../../services/api/phonepeApis";
import mixpanel from "mixpanel-browser";
import { mixpanelEvents } from "../../../services/mixpanel/events";
import { clevertapEvents } from "../../../services/clevertap/clevertapEvents";
import clevertap from "clevertap-web-sdk";
import { STORAGE_KEY, getStorageItem } from "../../../services/storage";
import { createPaymentEntry } from "../../../services/api/gsheet";

export const AssistedPaymentStatus = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const pollTimer = useRef();

  const [paymentStatus, setPaymentStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [pollCount, setPollCount] = useState(0);
  const txnId = getStorageItem(STORAGE_KEY.TXN_ID);
  const phoneNumber = getStorageItem(STORAGE_KEY.PHONE);

  const getPaymentStatus = async () => {
    setLoading(true);

    if (txnId) {
      setTimeout(async () => {
        try {
          const txnDetails = await getTransaction(txnId);
          if (txnDetails.exists()) {
            const { status } = txnDetails.data();
            if (
              status &&
              status !== PG_PAYMENT_STATUS.PAYMENT_CREATED &&
              status !== PG_PAYMENT_STATUS.PAYMENT_INITIATED &&
              status !== PG_PAYMENT_STATUS.PAYMENT_PENDING
            ) {
              setPaymentStatus(status);
              setLoading(false);
            } else {
              pollPaymentStatus();
            }
          }
        } catch (error) {
          setLoading(false);
        }
      }, 3000);
    }
  };
  const pollPaymentStatus = async () => {
    pollTimer.current = setInterval(async () => {
      try {
        const transactionStatus = await checkPaymentStatus(
          `${txnId}-${phoneNumber}`
        );

        if (
          !!transactionStatus.code &&
          transactionStatus.code !== PG_PAYMENT_STATUS.PAYMENT_PENDING &&
          transactionStatus.code !== PG_PAYMENT_STATUS.PAYMENT_CREATED &&
          transactionStatus.code !== PG_PAYMENT_STATUS.PAYMENT_INITIATED
        ) {
          clearInterval(pollTimer.current);
          const txnDetails = await getTransaction(txnId);
          const { status: currentStatus } = txnDetails.data();

          if (!isPaymentStatusUpdated(currentStatus)) {
            await updateTransaction(txnId, {
              phoneNumber,
              status: transactionStatus.code,
              pgResponse: transactionStatus,
            });
            setLoading(false);
            setPaymentStatus(transactionStatus.code);
            if (transactionStatus.code === PG_PAYMENT_STATUS.PAYMENT_SUCCESS) {
              const leadId = getStorageItem(STORAGE_KEY.LEAD_ID);
              const lead = await getLead(leadId);
              if (lead.exists()) {
                const { name, phoneNumber, utmSource, pincode } = lead.data();
                console.log(pincode);
                const amount = Math.floor(
                  transactionStatus?.data?.amount / 100
                );
                const paymentDate = new Date().toLocaleString("en-US", {
                  timeZone: "Asia/Kolkata",
                });
                await createPaymentEntry({
                  leadId,
                  txnId,
                  name,
                  phoneNumber,
                  utmSource,
                  amount,
                  paymentDate,
                  pincode,
                });
              }
            }
          }
        } else {
          setPollCount((prevState) => prevState + 1);
        }
      } catch (error) {}
    }, 3000);
  };

  const isPaymentFailed = () =>
    paymentStatus &&
    paymentStatus !== PG_PAYMENT_STATUS.PAYMENT_CREATED &&
    paymentStatus !== PG_PAYMENT_STATUS.PAYMENT_INITIATED &&
    paymentStatus !== PG_PAYMENT_STATUS.PAYMENT_SUCCESS;

  const isPaymentStatusUpdated = (status) =>
    !!status &&
    status !== PG_PAYMENT_STATUS.PAYMENT_INITIATED &&
    status !== PG_PAYMENT_STATUS.PAYMENT_CREATED;

  useEffect(() => {
    mixpanel.track(mixpanelEvents.ASSISTED_PAYMENT_STATUS_VIEWED);
    if (!window?.clevertap?.getAccountID()) {
      clevertap.init(process.env.REACT_APP_CLEVERTAP_PROJECT_ID, "eu1");
      clevertap.privacy.push({ optOut: false });
      clevertap.privacy.push({ useIP: true });
      clevertap.spa = true;
    }
    getPaymentStatus();
    if (pollTimer.current) {
      clearInterval(pollTimer.current);
    }
  }, []);

  useEffect(() => {
    if (pollCount > 10 && pollTimer.current) {
      clearInterval(pollTimer.current);
    }
  }, [pollCount]);

  useEffect(() => {
    if (paymentStatus === PG_PAYMENT_STATUS.PAYMENT_SUCCESS) {
      mixpanel.track(mixpanelEvents.ASSISTED_PAYMENT_SUCCESS);
      clevertap.event.push(
        clevertapEvents.ASSISTED_PAYMENT_SUCCESS_PAGE_VIEWED
      );
    } else if (
      !!paymentStatus &&
      paymentStatus !== PG_PAYMENT_STATUS.PAYMENT_CREATED &&
      paymentStatus !== PG_PAYMENT_STATUS.PAYMENT_INITIATED &&
      paymentStatus !== PG_PAYMENT_STATUS.PAYMENT_PENDING
    ) {
      mixpanel.track(mixpanelEvents.ASSISTED_PAYMENT_FAILED);
      clevertap.event.push(clevertapEvents.ASSISTED_PAYMENT_FAILED_PAGE_VIEWED);
    } else if (!!paymentStatus) {
      mixpanel.track(mixpanelEvents.ASSISTED_PAYMENT_PENDING);
      clevertap.event.push(
        clevertapEvents.ASSISTED_PAYMENT_PENDING_PAGE_VIEWED
      );
    }
  }, [paymentStatus]);

  return (
    <Layout>
      <div className={classes.root}>
        {loading && (
          <>
            <Loader />
            <h2 className={classes.title}>Processing your payment</h2>
            <p className={classes.text}>Don't go back or exit the page</p>
          </>
        )}
        {paymentStatus === PG_PAYMENT_STATUS.PAYMENT_SUCCESS && (
          <>
            <img src={SuccessImg} alt="Payment Success" />
            <h1 className={classes.title}>Your consultation slot is booked</h1>
            <p className={classes.text}>
              One of our executive will call you tomorrow in your preferred slot
            </p>
            <div className={classes.ctaContainer}>
              <button
                className={classes.btn}
                onClick={() => navigate(PAGES.home)}
              >
                Go To Home
              </button>
            </div>
          </>
        )}
        {isPaymentFailed() && (
          <>
            <img src={FailedImg} alt="Payment Failed" />
            <h1 className={classes.title}>Your payment has failed</h1>
            <p className={classes.text}>
              Make payment and book your slot to improve your cibil score
            </p>
            <div className={classes.ctaContainer}>
              <button
                className={classes.btn}
                onClick={() => navigate(PAGES.details)}
              >
                Try Again
              </button>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};
