import React from "react";
import { useStyles } from "./bookCall.styles";
import mixpanel from "mixpanel-browser";
import { mixpanelEvents } from "../../services/mixpanel/events";
import { useNavigate } from "react-router-dom";
import { PAGES } from "../../utils/constant";

export const BookCall = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const onBookNow = () => {
    mixpanel.track(mixpanelEvents.BOOK_NOW_BUTTON_CLICKED);
    navigate(PAGES.details);
  };
  return (
    <div className={classes.root}>
      <button className={classes.btn} onClick={onBookNow}>
        Book Call Now
      </button>
    </div>
  );
};
