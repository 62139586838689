import React from "react";
import { useStyles } from "./refundPolicy.styles";
import { Layout } from "../../components/Layout";

export function RefundPolicy() {
  const classes = useStyles();

  return (
    <Layout>
      <div className={classes.root}>
        <h5 className={classes.header}>Cancellation & Refund Policy</h5>
        <h6 className={classes.subheader}>Last updated on Dec 6th 2022</h6>

        <div className={classes.section}>
          <h6 className={classes.listHeader}>
            ARTHVIT 1809 TECH PRIVATE LIMITED believes in helping its customers
            as far as possible, and has therefore a liberal cancellation policy.
            Under this policy:
          </h6>
          <ul className={classes.list}>
            <li>
              <p className={classes.text}>
                Cancellations will be considered only if the request is made
                immediately after placing the order. However, the cancellation
                request may not be entertained if the orders have been
                communicated to the vendors/merchants and they have initiated
                the process of shipping them.
              </p>
            </li>
            <li>
              <p className={classes.text}>
                ARTHVIT 1809 TECH PRIVATE LIMITED does not accept cancellation
                requests for perishable items like flowers, eatables etc.
                However, refund/replacement can be made if the customer
                establishes that the quality of product delivered is not good.
              </p>
            </li>
            <li>
              <p className={classes.text}>
                In case of receipt of damaged or defective items please report
                the same to our Customer Service team. The request will,
                however, be entertained once the merchant has checked and
                determined the same at his own end. This should be reported
                within 30+ days of receipt of the products.
              </p>
            </li>
            <li>
              <p className={classes.text}>
                In case you feel that the product received is not as shown on
                the site or as per your expectations, you must bring it to the
                notice of our customer service within 30+ days of receiving the
                product. The Customer Service Team after looking into your
                complaint will take an appropriate decision.
              </p>
            </li>
            <li>
              <p className={classes.text}>
                In case of complaints regarding products that come with a
                warranty from manufacturers, please refer the issue to them.
              </p>
            </li>
            <li>
              <p className={classes.text}>
                In case of any Refunds approved by the ARTHVIT 1809 TECH PRIVATE
                LIMITED, it’ll take 3-5 days for the refund to be processed to
                the end customer.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  );
}
