export const PAGES = {
  home: "/",
  details: "/details",
  paymentsSuccess: "/payment-success",
  paymentFailed: "/payment-failed",
  assistedSuccess: "/assisted-success",
  assistedFailed: "/assisted-failed",
  paymentStatus: "/payment-status",
  paymentStatusAssisted: "/payment-status-assisted",
  terms: "/terms",
  privacyPolicy: "/privacy-policy",
  refundPolicy: "/refund-policy",
  deliveryPolicy: "/delivery-policy",
};
export const Version = "v1";
export const PlanAmount = 99;
export const DEFAULT_PRICING = 1;
export const DEFAULT_LANGUAGE = "en";
export const DEFAULT_HOMEPAGE_VARIANT = "1";
export const ACTIVE_PG = "phonepe";
export const PG_PAYMENT_STATUS = {
  PAYMENT_CREATED: "PAYMENT_CREATED",
  PAYMENT_INITIATED: "PAYMENT_INITIATED",
  PAYMENT_SUCCESS: "PAYMENT_SUCCESS",
  BAD_REQUEST: "BAD_REQUEST",
  AUTHORIZATION_FAILED: "AUTHORIZATION_FAILED",
  INTERNAL_SERVER_ERROR: "INTERNAL_SERVER_ERROR",
  PAYMENT_ERROR: "PAYMENT_ERROR",
  TRANSACTION_NOT_FOUND: "TRANSACTION_NOT_FOUND",
  PAYMENT_PENDING: "PAYMENT_PENDING",
  PAYMENT_DECLINED: "PAYMENT_DECLINED",
  TIMED_OUT: "TIMED_OUT",
};

export const queryParams = {
  PRICING_VARIANT: "vp",
  HOMEPAGE_VARIANT: "vh",
};

export const HOMEPAGE_VARIANT = {
  VIDEO_TOP: "1",
  TESTIMONIALS_TOP: "2",
};

export const faqs = [
  {
    id: "1",
    title: "Why does a good credit score matter?",
    description:
      "A good credit score increases your chances for loan and credit card approvals. You can get lower interest rates and better terms on credit products too. As a thumb rule, a score of 750 or higher is considered credit-worthy. A score of 650 or less is deemed too low to be given a loan.",
  },
  {
    id: "2",
    title: "How is credit score calculated?",
    description:
      "Your credit score is calculated on the basis of the following factors and their weightage:",
    list: [
      "Payment history",
      "Credit utilisation",
      "Multiple enquiries",
      "Credit mix",
      "Length of credit history, and more",
    ],
  },
  {
    id: "3",
    title: "What is the ideal credit score needed for buying a car?",
    description:
      "There's no legal credit score requirement for buying a car. However, a credit score of 750 or more gets you the best deals on car loans at the lowest interest rates.",
  },
  {
    id: "4",
    title: "How long does it take to improve credit score?",
    description:
      "The time required for rebuilding credit score varies on a case to case basis. In some cases, it can improve in just 3-6 months, but it may even take 4-12 months for others with a very low score.",
  },
];
