import React, { useState } from "react";
import { useStyles } from "./testimonials.styles";
import Slider from "react-slick";
import YouTube from "react-youtube";
import "./slide.css";
import mixpanel from "mixpanel-browser";
import { mixpanelEvents } from "../../../services/mixpanel/events";

export const Testimonials = () => {
  const [players, setPlayers] = useState([]);
  const classes = useStyles();
  var settings = {
    className: "slider variable-width",
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  };

  const opts = {
    height: "356",
    width: "200",
  };

  const onPlayerReady = (player, index) => {
    const prevPlayers = [...players];
    prevPlayers[index] = player;
    setPlayers(prevPlayers);
  };

  const onVideoPlay = (playerIndex, name) => {
    mixpanel.track(mixpanelEvents.TESTIMONIAL_PLAYED, { name });
    players.map((player, index) => {
      if (index !== playerIndex && typeof player?.stopVideo === "function") {
        player?.stopVideo();
      }
    });
  };

  const onVideoPause = (name) => {
    mixpanel.track(mixpanelEvents.TESTIMONIAL_PAUSED, {
      name,
    });
  };

  const onVideoEnd = (name) => {
    mixpanel.track(mixpanelEvents.TESTIMONIAL_END, {
      name,
    });
  };

  const onVideoError = (name) => {
    mixpanel.track(mixpanelEvents.TESTIMONIAL_PLAY_ERROR, {
      name,
    });
  };

  return (
    <div className={classes.root}>
      <h3 className={classes.title}>Customer Reviews</h3>
      <Slider {...settings}>
        <div className={classes.container}>
          <div className={classes.box}>
            <YouTube
              id="video1"
              videoId="nBoJc-THbXU"
              opts={opts}
              onReady={(e) => onPlayerReady(e.target, 0)}
              onPlay={() => onVideoPlay(0, "Monica")}
              onPause={() => onVideoPause("Monica")}
              onEnd={() => onVideoEnd("Monica")}
              onError={() => onVideoError("Monica")}
            />
          </div>
        </div>
        <div className={classes.container}>
          <div className={classes.box}>
            <YouTube
              id="video2"
              videoId="1MvQ_mSXGts"
              opts={opts}
              onReady={(e) => onPlayerReady(e.target, 1)}
              onPlay={() => onVideoPlay(1, "Nagraj")}
              onPause={() => onVideoPause("Nagraj")}
              onEnd={() => onVideoEnd("Nagraj")}
              onError={() => onVideoError("Nagraj")}
            />
          </div>
        </div>
        <div className={classes.container}>
          <div className={classes.box}>
            <YouTube
              id="video3"
              videoId="mJqe_XrF4Ms"
              opts={opts}
              onReady={(e) => onPlayerReady(e.target, 2)}
              onPlay={() => onVideoPlay(2, "Puja")}
              onPause={() => onVideoPause("Puja")}
              onEnd={() => onVideoEnd("Puja")}
              onError={() => onVideoError("Puja")}
            />
          </div>
        </div>
        <div className={classes.container}>
          <div className={classes.box}>
            <YouTube
              id="video4"
              videoId="cscdGkT7MRc"
              opts={opts}
              onReady={(e) => onPlayerReady(e.target, 3)}
              onPlay={() => onVideoPlay(3, "Praveen")}
              onPause={() => onVideoPause("Praveen")}
              onEnd={() => onVideoEnd("Praveen")}
              onError={() => onVideoError("Praveen")}
            />
          </div>
        </div>
      </Slider>
    </div>
  );
};
