import React, { createContext, useState } from "react";
import { PlanAmount } from "../utils/constant";
export const context = createContext();

export const ContextProvider = ({ children }) => {
  const [utmSource, setUtmSource] = useState("");
  const [utmMedium, setUtmMedium] = useState("");
  const [utmCampaign, setUtmCampaign] = useState("");
  const [utmContent, setUtmContent] = useState("");
  const [chat, setChat] = useState(true);
  const [lang, setLang] = useState("en");
  const contextValues = {
    utmSource,
    setUtmSource,
    utmMedium,
    setUtmMedium,
    utmCampaign,
    setUtmCampaign,
    utmContent,
    setUtmContent,
    PlanAmount,
    chat,
    setChat,
    lang,
    setLang,
  };
  return <context.Provider value={contextValues}>{children}</context.Provider>;
};
