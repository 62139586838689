import React, { useEffect } from "react";
import { useStyles } from "./failed.styles";
import { Layout } from "../../../components/Layout";
import { useNavigate } from "react-router-dom";
import { PAGES } from "../../../utils/constant";
import mixpanel from "mixpanel-browser";
import clevertap from "clevertap-web-sdk";
import { mixpanelEvents } from "../../../services/mixpanel/events";
import { clevertapEvents } from "../../../services/clevertap/clevertapEvents";
import FailedImg from "../../../assets/img//failed.svg";
export const PaymentFailed = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    if (window?.fbq) {
      window.fbq("track", "AddToWishlist");
    }
    mixpanel.track(mixpanelEvents.PAYMENT_FAILED_VIEWED);
    clevertap.event.push(clevertapEvents.PAYMENT_FAILED_PAGE_VIEWED);
  }, []);

  return (
    <Layout>
      <div className={classes.root}>
        <img src={FailedImg} alt="Payment Failed" />
        <h1 className={classes.title}>Your payment has failed</h1>
        <p className={classes.text}>
          Make payment and book your slot to improve your cibil score
        </p>
      </div>
      <div className={classes.ctaContainer}>
        <button className={classes.btn} onClick={() => navigate(PAGES.details)}>
          Try Again
        </button>
      </div>
    </Layout>
  );
};
