import { useSearchParams } from "react-router-dom";
import { DEFAULT_LANGUAGE } from "../utils/constant";
import {
  STORAGE_KEY,
  getStorageItem,
  setStorageItem,
} from "../services/storage";
export function useLanguage() {
  const [searchParams] = useSearchParams();
  const currentLang = searchParams.get("lang");
  const savedLang = getStorageItem(STORAGE_KEY.LANGUAGE);
  const preferredLang = currentLang || savedLang || DEFAULT_LANGUAGE;

  if (currentLang && currentLang !== savedLang) {
    setStorageItem(STORAGE_KEY.LANGUAGE, preferredLang);
  }

  return {
    lang: preferredLang,
  };
}
