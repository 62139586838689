import React, { useEffect } from "react";
import { Layout } from "../../components/Layout";
import {
  TopSection,
  Video,
  CibilScore,
  HowItWorks,
  FAQ,
  Offerings,
  Testimonials,
} from "../../components/HomepageSections";
import mixpanel from "mixpanel-browser";
import { mixpanelEvents } from "../../services/mixpanel/events";
import { BookCall } from "../../components/BookCall";
import { useHomeVariant } from "../../hooks/useHomeVariant";
import { HOMEPAGE_VARIANT } from "../../utils/constant";

export const Home = () => {
  const { variant } = useHomeVariant();
  useEffect(() => {
    mixpanel.track(mixpanelEvents.HOMEPAGE_VIEWED);
  }, []);

  return (
    <Layout showFooterCta={true}>
      <TopSection />
      <CibilScore />
      {variant === HOMEPAGE_VARIANT.TESTIMONIALS_TOP ? (
        <Testimonials />
      ) : (
        <Video />
      )}

      <Offerings />
      <HowItWorks />
      {variant === HOMEPAGE_VARIANT.TESTIMONIALS_TOP ? (
        <Video />
      ) : (
        <Testimonials />
      )}
      <FAQ />
      <BookCall />
    </Layout>
  );
};
