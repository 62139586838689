import React, { useContext } from "react";
import { useStyles } from "./hiw.styles";
import HIWImg from "../../../assets/img/HowItWorks.svg";
import HIWImgHindi from "../../../assets/img/steps-hindi.svg";
import { useTranslation } from "react-i18next";
import { context } from "../../../store/context";
import Step1Img from "../../../assets/img/step1.png";
import Step2Img from "../../../assets/img/step2.png";
import Step3Img from "../../../assets/img/step3.svg";
import { usePrice } from "../../../hooks/usePrice";
export const HowItWorks = () => {
  const classes = useStyles();
  const { lang } = useContext(context);
  const { t } = useTranslation();
  const { formattedPrice } = usePrice();
  return (
    <section className={classes.root}>
      <h1 className={classes.header}>{t("home.hiw.header")}</h1>
      {/* <div className={classes.imgContainer}>
        {lang === "hien" ? (
          <img src={HIWImgHindi} alt="How It Works Steps" />
        ) : (
          <img src={HIWImg} alt="How It Works Steps" />
        )}
      </div> */}
      <div>
        <div className={classes.step1}>
          <div className={classes.timeLine}></div>
          <div className={classes.circle}></div>
          <div className={classes.stepTitleWrpr}>
            <h1 className={classes.title}>
              Step <span className={classes.number}>1</span>
            </h1>
            <img src={Step1Img} alt="Book" className={classes.stepIcon} />
          </div>
          <h2 className={classes.subTiitle}>{t("home.hiw.step1")}</h2>
        </div>
        <div className={classes.step2}>
          <div className={classes.timeLine}></div>
          <div className={classes.circle}></div>
          <div className={classes.stepTitleWrpr}>
            <h1 className={classes.title}>
              Step <span className={classes.number}>2</span>
            </h1>
            <img src={Step2Img} alt="Pay" className={classes.stepIcon} />
          </div>
          <h2 className={classes.subTiitle}>
            {t("home.hiw.step2Text1")} {formattedPrice}{" "}
            {t("home.hiw.step2Text2")}
          </h2>
        </div>
        <div className={classes.step3}>
          <div className={classes.circle}></div>
          <div className={classes.stepTitleWrpr} style={{ minHeight: 60 }}>
            <h1 className={classes.title}>
              Step <span className={classes.number}>3</span>
            </h1>
            <img src={Step3Img} alt="Get a call" />
          </div>
          <h2 className={classes.subTiitle}>{t("home.hiw.step3")}</h2>
        </div>
      </div>
    </section>
  );
};
