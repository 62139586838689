import { createUseStyles } from "react-jss";
import CibilBg from "../../../assets/img/cibil-bg.png";
export const useStyles = createUseStyles({
  root: {},
  imgWrpr: {
    backgroundImage: `url(${CibilBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
  },
  img: {
    width: "100%",
  },

  content: {
    display: "flex",
    marginTop: "1em",
  },
  section: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    alignItems: "center",
  },
  row: {
    display: "flex",
  },
  separator: {
    margin: ".375em",
  },
  verticalSeparatorWrpr: {
    padding: 16,
  },
  header: {
    fontSize: "1.25rem",
    fontWeight: 700,
    lineHeight: 1.5,
    margin: 0,
  },
  subHeader: {
    fontSize: "1.25rem",
    fontWeight: 300,
    lineHeight: 1.5,
    margin: 0,
  },
  rowWrpr: {
    marginTop: "1em",
  },
  text: {
    fontSize: "1.125rem",
    fontWeight: 600,
    lineHeight: 1.5,
    margin: "0 .625em",
  },
});
