import React from "react";
import { useStyles } from "./privacyPolicy.styles";
import { Layout } from "../../components/Layout";

export function PrivacyPolicy() {
  const classes = useStyles();

  return (
    <Layout>
      <div className={classes.root}>
        <h5 className={classes.header}>Privacy Policy</h5>
        <h6 className={classes.subheader}>Last updated on Dec 6th 2022</h6>

        <div className={classes.section}>
          <h1 className={classes.listHeader}>
            We are committed to safeguarding your privacy, and this privacy
            policy explains how and why Arthvit 1809 Tech Pvt. Ltd. (“We”, “Us”,
            “Arthvit”) collects and uses any personal information or data when
            someone (“You”, “Your”, “User”, “Cardholder/Cardmember”), uses the
            RupiSeva website.
          </h1>
          <p className={classes.contentHeader}>Information provided by you</p>
          <h6 className={classes.listHeader}>
            To avail certain services on our RupiSeva Website, you are required
            to provide some personally identifiable information for the
            pre-registration process, which includes but is not limited to the
            following:
          </h6>
          <ul className={classes.list}>
            <li>
              <p className={classes.text}>Your name</p>
            </li>
            <li>
              <p className={classes.text}>Email address</p>
            </li>
            <li>
              <p className={classes.text}>Mobile number</p>
            </li>
            <li>
              <p className={classes.text}>Pincode</p>
            </li>
          </ul>
          <p className={classes.content}>
            We may require you to provide additional details, as and when
            required, in order to comply with any applicable regulatory
            requirement or for additional services/products via the Website, as
            and when offered, and may also utilise data lawfully obtained from
            third party service providers authorised by you, including but not
            limited to data pertaining to your credit score, to provide the
            Website services to you, subject to the terms of this Privacy
            Policy.
          </p>
          <p className={classes.contentHeader}>Express Consent</p>
          <p className={classes.content}>
            While providing your details/documents via the Website, including
            but not limited to personal information as mentioned herein above,
            you expressly consent to Arthvit (including its marketing channels
            and business partners) to contact you through SMS, call and/or
            e-mail and to follow up with regard to the services provided through
            the Website, for imparting product knowledge, offering promotional
            offers running on the Website & various other offers/services by our
            business partners. The Website requires you to provide consent for
            keying in or uploading your personal information, as may be
            necessary to process your application. Any personal information
            which requires to be keyed in or uploaded is required for enabling
            hassle free, faster and paperless (to the extent possible)
            processing of applications for financial products so opted or
            availed of by you. You hereby give your consent to Arthvit to obtain
            your KYC details from CERSAI CKYC portal.{" "}
          </p>
          <p className={classes.contentHeader}>
            Information automatically collected while using the website
          </p>
          <p className={classes.content}>
            We strive to keep automatically tracked information to a minimum by
            obtaining no permissions from the Website, unless absolutely
            necessary to enable proper functioning of the Website. However, we
            may collect information relating to your use of our Website such as
            your IP address, browser type, access time and time spent. We may
            also collect information about the screens/pages you view within our
            Website and other actions you take while using our Website. We may
            use third party services for such automatic collection, however no
            personally identifiable information will be shared with such third
            parties, unless required under applicable laws.
          </p>
          <p className={classes.contentHeader}>Use of Personal Information</p>
          <p className={classes.content}>
            We do not share any information with third parties for soliciting
            products and services offered by them. Information automatically
            tracked while using the Website allows us to collect statistics
            about your usage, allowing us to deliver a more effective and
            personalized experience.
          </p>
          <p className={classes.contentHeader}>Information Sharing</p>
          <p className={classes.content}>
            We do not share any of your personal information with any third
            parties except when it is requested or required by law or by any
            court or governmental agency or authority to disclose, for the
            purpose of verification of identity, or for the prevention,
            detection, investigation including cyber incidents, or for
            prosecution and punishment of offences. We may need to share limited
            personal information with our partners who perform services for us
            (eg. KYC / documents collection, card delivery, Collection Agencies
            and other related partners, etc) and help us operate our business.
            We require our partners to safeguard this information and only use
            your personal information for the purposes we specify.
          </p>
          <p className={classes.contentHeader}>Information Security</p>
          <p className={classes.content}>
            We take appropriate security measures to protect against
            unauthorised access. These include internal reviews of our data
            collection, storage and processing practices and security measures,
            including appropriate encryption and physical security measures to
            guard against unauthorised access to systems where we store personal
            data. All information gathered on our Website is securely stored
            within a controlled database. Access to the servers is password
            protected, controlled, and is strictly limited.
          </p>
          <p className={classes.contentHeader}>Data Retention</p>
          <p className={classes.content}>
            We will retain your information for as long as it is necessary for
            providing you the services available on the Website or your request
            for termination of your account with us, whichever is later. Post
            termination of your account, we may continue to use your anonymised
            data aggregated or in combination with anonymised data of other
            users. We use this aggregated anonymised data for data analysis,
            profiling and research purposes. We may keep your contact
            information along with your application details (if any) for fraud
            prevention purposes and for the exercise/ defense of a legal claim
            or for providing evidence in legal proceeding(s) if required. If you
            wish to review, correct or rectify your sensitive personal
            information or withdraw your consent for processing your sensitive
            personal information, you may submit a request at
            grievances@arthvit.in. However, we will continue to retain sensitive
            personal information as per the applicable laws.
          </p>

          <p className={classes.contentHeader}>Log Files</p>
          <p className={classes.content}>
            This information may include Internet Protocol (IP) addresses,
            browser type, Internet Service Provider (ISP), referring/exit pages,
            operating system, date/time stamp, and/or clickstream data. We may
            use the collected log information about you to improve services
            offered to you, to improve marketing, analytics, or Website
            functionality.
          </p>
          <p className={classes.contentHeader}>
            Cookies and Do Not Track / Incognito mode settings
          </p>
          <p className={classes.content}>
            The RupiSeva website uses Google Analytics, a web analytics service
            provided by Google. This software uses cookies that are text files
            containing small amounts of information which are downloaded to your
            device when you visit a website, in order to provide a personalised
            browsing experience. These cookies help Google identify unique
            users, unique sessions, gather information and store information. No
            personally identifiable information is retrieved or stored. Cookies
            allow users to navigate between pages efficiently, remembering their
            preferences, and generally improving their browsing experience.
            These cookies collect information analytics about how users use a
            website, for instance, often visited pages, time spent on each page
            etc. All information collected by third party cookies is aggregated
            and therefore anonymous. By using the RupiSeva website, the user
            agrees that these types of cookies can be placed on his / her
            device. The user is free to disable/ delete these cookies by
            changing his / her web browser settings. Arthvit and its affiliates
            are not responsible for cookies placed in the device of Users by any
            other website and information collected thereto.
          </p>
          <p className={classes.contentHeader}>Grievance Redressal Policy</p>
          <p className={classes.content}>
            If you have any grievance or complaint, questions, comments,
            concerns or feedback in relation to the processing of information or
            regarding this Privacy Policy or any other privacy or security
            concern related to the Website, you can send an email to us on
            grievances@arthvit.in.
          </p>
        </div>
      </div>
    </Layout>
  );
}
