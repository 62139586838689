import React from "react";
import { useStyles } from "./faq.styles";
import { Accordion } from "../../Accordion";
import { useTranslation } from "react-i18next";
export const FAQ = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const faqs = [
    {
      id: t("home.faq.first.id"),
      title: t("home.faq.first.title"),
      description: t("home.faq.first.description"),
    },
    {
      id: t("home.faq.second.id"),
      title: t("home.faq.second.title"),
      description: t("home.faq.second.description"),
      list: t("home.faq.second.list", { returnObjects: true }),
    },

    {
      id: t("home.faq.third.id"),
      title: t("home.faq.third.title"),
      description: t("home.faq.third.description"),
    },
    {
      id: t("home.faq.fourth.id"),
      title: t("home.faq.fourth.title"),
      description: t("home.faq.fourth.description"),
    },
  ];
  return (
    <section className={classes.root}>
      <h1 className={classes.header}>FAQ</h1>
      <Accordion items={faqs} />
    </section>
  );
};
