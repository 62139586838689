import React from "react";
import { useStyles } from "./deliveryPolicy.styles";
import { Layout } from "../../components/Layout";

export function DeliveryPolicy() {
  const classes = useStyles();

  return (
    <Layout>
      <div className={classes.root}>
        <h5 className={classes.header}>Shipping & Delivery Policy</h5>
        <h6 className={classes.subheader}>Last updated on Dec 6th 2022</h6>
        <div className={classes.section}>
          <p className={classes.text}>
            For domestic buyers, orders are shipped through registered domestic
            courier companies and /or speed post only. Orders are shipped within
            0-2 days or as per the delivery date agreed at the time of order
            confirmation and delivering of the shipment subject to Courier
            Company / post office norms. ARTHVIT 1809 TECH PRIVATE LIMITED is
            not liable for any delay in delivery by the courier company / postal
            authorities and only guarantees to hand over the consignment to the
            courier company or postal authorities within 0-2 days from the date
            of the order and payment or as per the delivery date agreed at the
            time of order confirmation. Delivery of all orders will be to the
            address provided by the buyer. Delivery of our services will be
            confirmed on your mail ID as specified during registration. For any
            issues in utilizing our services you may contact our helpdesk on or
            support@rupicard.com
          </p>
        </div>
      </div>
    </Layout>
  );
}
