import React from "react";
import TickRoundIcon from "../../../assets/icon/tick-round.svg";
import { useStyles } from "./top.styles";
import PricingBanner from "../../../assets/img/price-banner.svg";
import CibilGuru from "../../../assets/img/cibil-guru.svg";
import CibilIncrease from "../../../assets/img/cibil-increase.svg";
import { useTranslation } from "react-i18next";
import { usePrice } from "../../../hooks/usePrice";
export const TopSection = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { formattedPrice } = usePrice();
  return (
    <section className={classes.root}>
      <div className={classes.banner}>
        <p className={classes.bannerHeader}>@ Just</p>
        <h1 className={classes.bannerPrice}>{formattedPrice}</h1>
        <p className={classes.bannerSubHeader}>
          No hidden
          <br /> charges
        </p>
      </div>

      <h1 className={classes.header}>{t("home.top.header")}</h1>
      <div>
        <div className={classes.row}>
          <img src={CibilGuru} alt="Cibil Guru" />
          <p className={classes.text}>
            {t("home.top.listText1Sub1")}{" "}
            <span className={classes.colorText}>
              {t("home.top.listText1Sub2")}
            </span>{" "}
            {t("home.top.listText1Sub3")}
            <b>
              {" "}
              {t("home.top.listText1Sub4")} {formattedPrice}{" "}
              {t("home.top.listText1Sub5")}{" "}
            </b>
          </p>
        </div>
        <div className={classes.row}>
          <img src={CibilIncrease} alt="Increase Cibil" />
          <p className={classes.text}>
            {t("home.top.listText2Sub1")} <b>{t("home.top.listText2Sub2")}</b>
            {t("home.top.listText2Sub3")}
          </p>
        </div>
      </div>
    </section>
  );
};
