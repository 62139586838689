import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  root: {
    "@media (min-width: 480px)": {
      maxWidth: "480px",
      margin: "0 auto",
      position: "relative",
      borderLeft: "1px solid #e0e0e0",
      borderRight: "1px solid #e0e0e0",
    },
  },
  main: {},
});
