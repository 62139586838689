export const createPaymentEntry = async (data) => {
  const G_SHEET_URL = `https://script.google.com/macros/s/AKfycbyOCy4F77dqds8Vb9rOS0aHTvPhExcmXXOA9nCvN3Set1VRAaVzBubE_0vzpz2Zz7_7/exec`;
  try {
    const response = await fetch(G_SHEET_URL, {
      method: "POST",
      body: JSON.stringify(data),
    });

    return response;
  } catch (error) {
    return error;
  }
};
