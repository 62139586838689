export const STORAGE_KEY = {
  UTM_SOURCE: "source",
  LANGUAGE: "lang",
  PRICE_VARIANT: "vp",
  LEAD_ID: "leadId",
  TXN_ID: "txnId",
  PHONE: "phoneNumber",
  PAYMENT_SUCCESS: "paymentSuccess",
  PAYMENT_FAILED: "paymentFailed",
  HOMEPAGE_VARIANT: "vh",
};

export const setStorageItem = (key, value) => {
  localStorage.setItem(key, value);
  return true;
};
export const getStorageItem = (key) => localStorage.getItem(key);

export const removeStorageItem = (key) => {
  localStorage.removeItem(key);
  return;
};
