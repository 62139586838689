import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  thumbnailContainer: ({ isPlayerReady }) => ({
    display: !isPlayerReady ? "block" : "none",
    position: "relative",
    overflow: "hidden",
    width: "100%",
    paddingTop: "0%",
    marginTop: 100,
    marginRight: "1%",
    marginBottom: "7%",
    minHeight: "401px",
    "@media (min-width: 200px)": {
      minHeight: "197px",
      width: "calc(100% - 64px)",
      marginLeft: "32px",
      marginRight: "32px",
      marginTop: 32,
      paddingTop: "1%",
      marginBottom: 16,
      borderRadius: 10,
    },
  }),
  thumbnail: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
  container: ({ isPlayerReady }) => ({
    display: isPlayerReady ? "block" : "none",
    position: "relative",
    overflow: "hidden",
    width: "100%",
    paddingTop: "29%",
    marginTop: 100,
    marginRight: "1%",
    marginBottom: "7%",
    "@media (min-width: 200px) ": {
      width: "calc(100% - 64px)",
      marginLeft: "32px",
      marginRight: "32px",
      marginTop: 32,
      paddingTop: "56.29%",

      marginBottom: 16,
      borderRadius: 10,
    },
  }),
  iframe: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
  },
});
