import React, { useEffect } from "react";
import { useStyles } from "./assistedSuccess.styles";
import { Layout } from "../../../components/Layout";
import { useNavigate } from "react-router-dom";
import { PAGES } from "../../../utils/constant";
import mixpanel from "mixpanel-browser";
import clevertap from "clevertap-web-sdk";
import { mixpanelEvents } from "../../../services/mixpanel/events";
import { clevertapEvents } from "../../../services/clevertap/clevertapEvents";
import SuccessImg from "../../../assets/img/success.svg";
export const AssistedPaymentSuccess = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    mixpanel.track(mixpanelEvents.ASSISTED_PAYMENT_SUCCESS);
    clevertap.event.push(clevertapEvents.ASSISTED_PAYMENT_SUCCESS_PAGE_VIEWED);
  }, []);
  return (
    <Layout>
      <div className={classes.root}>
        <img src={SuccessImg} alt="Payment Success" />
        <h1 className={classes.title}>Your consultation slot is booked</h1>
        <p className={classes.text}>
          One of our executive will call you tomorrow in your preferred slot
        </p>
      </div>
      <div className={classes.ctaContainer}>
        <button className={classes.btn} onClick={() => navigate(PAGES.home)}>
          Go To Home
        </button>
      </div>
    </Layout>
  );
};
