import {
  collection,
  addDoc,
  serverTimestamp,
  updateDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import { firestore } from "./firebase";
import { PG_PAYMENT_STATUS } from "../utils/constant";
export const saveLeads = (data = {}) =>
  addDoc(collection(firestore, "leads"), {
    ...data,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  });

export const createTransaction = (leadId, pricing, pg, phoneNumber) =>
  addDoc(collection(firestore, "transactions"), {
    phoneNumber,
    leadId,
    amount: pricing,
    pg,
    status: PG_PAYMENT_STATUS.PAYMENT_CREATED,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  });

export const updateTransaction = (txnId, data = {}) =>
  updateDoc(doc(firestore, "transactions", txnId), {
    ...data,
    updatedAt: serverTimestamp(),
  });

export const getTransaction = (txnId) =>
  getDoc(doc(firestore, "transactions", txnId));

export const getLead = (leadId) => getDoc(doc(firestore, "leads", leadId));
