import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  root: {
    padding: "3rem 2rem",
  },
  content: {
    border: "3px solid #005CA8",
    background: "#EBF6FF",
    borderRadius: 22,
    padding: "1.5rem 1.875rem",
  },
  headerWrpr: {
    position: "relative",
  },
  header: {
    fontSize: "1.5rem",
    fontWeight: 600,
    lineHeight: 1.5,
    color: "#005CA8",
    margin: 0,
    marginRight: "6rem",
  },
  tipHuman: {
    position: "absolute",
    right: "-2rem",
    top: "1rem",
  },
  list: { marginTop: "3.5rem" },
  listItem: {
    display: "flex",
    margin: "1.5rem 0",
  },
  listText: {
    fontSize: "1.125rem",
    fontWeight: 600,
    lineHeight: 1.5,
    margin: "0 0 0 .875rem",
  },
  ctaContainer: {
    width: "100%",
    padding: "5rem 0 0 0",
    boxSizing: "border-box",
  },
  btn: {
    width: "100%",
    backgroundColor: "#FF7800",
    padding: "12px 32px",
    fontSize: "1.5rem",
    fontWeight: 600,
    color: "#FFFFFF",
    border: "3px solid #FDEFEF",
    borderRadius: 10,
    filter: "drop-shadow(0px 1px 32px rgba(0, 0, 0, 0.25))",
  },
});
