import { useSearchParams } from "react-router-dom";
import { DEFAULT_PRICING } from "../utils/constant";
import { getPrice } from "../utils";
import {
  STORAGE_KEY,
  getStorageItem,
  setStorageItem,
} from "../services/storage";
export function usePrice() {
  const [searchParams] = useSearchParams();
  const currentPrice = searchParams.get("vp");
  const savedPrice = getStorageItem(STORAGE_KEY.PRICE_VARIANT);
  const appliedPrice = currentPrice || savedPrice || DEFAULT_PRICING;

  if (currentPrice && currentPrice !== savedPrice) {
    setStorageItem(STORAGE_KEY.PRICE_VARIANT, currentPrice);
  }

  return {
    price: getPrice(appliedPrice),
    formattedPrice: `₹${getPrice(appliedPrice)}`,
  };
}
