import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  item: {
    overflow: "hidden",
    marginBottom: "1em",
    padding: "1.125em 1.5em",
    backgroundColor: "#fff",
  },
  toggle: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#fff",
    transition: "0.3s",
  },
  toggleActive: {},
  title: {
    fontSize: "1.125rem",
    fontWeight: 700,
    lineHeight: 1.2,
    position: "relative",
    transition: "0.3s",
    margin: 0,
  },
  icon: {},
  iconActive: {
    transform: " rotate(90deg)",
    color: "#fff",
  },
  collapse: {
    position: "relative",
    height: 0,
    overflow: "hidden",
    transition: "height 0.35s ease",
    background: "#FFFFFF",
  },
  collapseShow: {
    height: "auto",
  },
  description: {},
  list: {
    listStyle: "auto",
  },
});
