import React from "react";
import LogoFooter from "../../assets/logo/rupiplus-logo.svg";
import Insta from "../../assets/icon/instagram.svg";
import facebook from "../../assets/icon/facebook.svg";
import linkedIn from "../../assets/icon/linkedin.svg";
import Youtube from "../../assets/icon/youtube.svg";
import Phone from "../../assets/icon/phone.svg";
import Email from "../../assets/icon/email.svg";
import Location from "../../assets/icon/location.svg";
import { useStyles } from "./footer.styles";
import mixpanel from "mixpanel-browser";
import { Link, useNavigate } from "react-router-dom";
import { PAGES } from "../../utils/constant";
import { mixpanelEvents } from "../../services/mixpanel/events";
export const Footer = ({ showCta = false }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const openLink = (link) => window.open(link, "_blank", "noopener,noreferrer");
  const onBookNow = () => {
    mixpanel.track(mixpanelEvents.BOOK_NOW_BUTTON_CLICKED);
    navigate(PAGES.details);
  };

  return (
    <footer className={classes.root}>
      <img src={LogoFooter} className={classes.logo} />
      <div className={classes.socialLinks}>
        <img
          src={Insta}
          className={classes.icons}
          onClick={() => openLink("https://www.instagram.com/rupicard")}
        />
        <img
          src={facebook}
          className={classes.icons}
          onClick={() => openLink("https://www.facebook.com/rupicard")}
        />
        <img
          src={linkedIn}
          className={classes.icons}
          onClick={() => openLink("https://www.linkedin.com/company/rupicard/")}
        />
        <img
          src={Youtube}
          className={classes.instaIcon}
          onClick={() =>
            openLink("https://www.youtube.com/channel/UC4WDmOIU0xP5isVhq4siRFQ")
          }
        />
      </div>
      <div>
        <h5 className={classes.sectionTitle}>Pages</h5>
        <div className={classes.linkContainer}>
          <Link to={PAGES.terms} className={classes.link}>
            Terms & Conditions
          </Link>
          <Link to={PAGES.privacyPolicy} className={classes.link}>
            Privacy Policy
          </Link>
          <Link to={PAGES.deliveryPolicy} className={classes.link}>
            Delivery Policy
          </Link>
          <Link to={PAGES.refundPolicy} className={classes.link}>
            Refund Policy
          </Link>
        </div>
      </div>
      <div>
        <h5>Contact</h5>
        <div className={classes.contactRow}>
          <img src={Phone} />
          <p className={classes.text}>080-69819393</p>
        </div>
        <div className={classes.contactRow}>
          <img src={Email} />
          <p className={classes.text}>support@rupicard.com</p>
        </div>
        <div className={classes.contactRow}>
          <img src={Location} />
          <p className={classes.text}>
            B-502, Celestial Greens Apartment, CV Raman Nagar, Bengaluru -
            560093
          </p>
        </div>
      </div>
      {/* {showCta && (
        <div className={classes.ctaContainer}>
          <button className={classes.btn} onClick={onBookNow}>
            Book Call Now!
          </button>
        </div>
      )} */}
    </footer>
  );
};
