import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import english from "../../assets/localization/english.json";
import hinglish from "../../assets/localization/hinglish.json";

export const localizationResource = {
  en: {
    translation: english,
  },
  hien: {
    translation: hinglish,
  },
};

export const initLocalization = (preferredLanguage) => {
  i18n.use(initReactI18next).init({
    resources: localizationResource,
    lng: preferredLanguage,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });
};
